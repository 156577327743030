import { setHtmlAttribute } from "@/utils";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import FaMoon from "../SVG/Fontawesome/FaMoon";
import SunIcon from "../SVG/SunIcon";

const ThemeChanger = (props: { className?: string }) => {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  const handleChangeTheme = (theme: string) =>{
    setTheme(theme);
    setHtmlAttribute("class", theme);
  }

  return (
    <label className={`mr-2 relative inline-flex cursor-pointer items-center justify-center bg-accent p-0 rounded-full ${props.className ?? ""}`}>
      <button name="light-accent" id="light-accent" onClick={() => handleChangeTheme("light")} className={`flex basis-1/2 justify-center items-center rounded-full py-1.5 px-2.5 text-sm font-medium transition-all ${(theme === "light" && mounted) ? "bg-secondary-light text-accent" : "text-white"}`}>
        <SunIcon />
      </button>
      <button name="dark-accent" id="dark-accent" onClick={() => handleChangeTheme("dark")} className={`flex basis-1/2 justify-center items-center rounded-full py-1.5 px-2.5 text-sm font-medium transition-all ${(theme === "dark" && mounted) ? "bg-secondary-light text-accent" : "text-white"}`}>
        <FaMoon />
      </button>
    </label>
  )
}

export default ThemeChanger;
