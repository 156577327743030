import { Disclosure } from "@headlessui/react";
import FaChevronDown from "../SVG/Fontawesome/FaChevronDown";
import IconGeneric from "../SVG";
import LinkWrapper from "../LinkWrapper";
import { HamburgerFixed } from "../Header/hamburger";
import { IAccordionItem } from "./interfaces";
import { memo } from "react";

const AccordionContent = (props: { data: IAccordionItem }) => {
  const data = props.data;

  // only has single accordion item.
  if (!data.list) {
    return <div>
      {data.icon && <IconGeneric name={data.icon} />}
      {data.content}
    </div>
  }
  return <>
    {data.list.map((item, index) => (<li className="flex items-center gap-2 pl-0" key={index.toString()}>
      {item.icon && <IconGeneric className='shrink-0 h-6' name={item.icon} />}
      {item.href && <LinkWrapper href={item.href.toString()}><span className="font-medium link">{item.title}</span></LinkWrapper>}
      {!item.href && <span className="font-medium">{item.title ? item.title : item}</span>}
    </li>)
    )}
  </>
};

// memo here as this component is called in recursion
const AccordionItem = memo((props: IAccordionItem) => {
  const handleDisclosureButtonClick = (event: any) => {
    event.stopPropagation();
    // TODO: maybe check if nesting is there and not based on classname sidenav 
    const parentElement = props.parentClassName?.includes("sidenav") ? document.querySelectorAll(`.${props.parentClassName} .disclosure-button`) : document.querySelector(`.${props.parentClassName}`);

    if (!parentElement) return;
    const disclosureButtons = props.parentClassName?.includes("sidenav") ? parentElement : (parentElement as any).querySelectorAll(".disclosure-button");
    for (let i = 0; i < disclosureButtons.length; i++) {
      const disclosureButton = disclosureButtons.item(i);
      if (
        disclosureButton !== event.currentTarget &&
        disclosureButton?.getAttribute("data-headlessui-state") === "open"
      ) {
        disclosureButton?.click();
      }
    }
  };

  return (
    <div className={`w-full ${props.parentClassName}`} >
      <div className="mx-auto w-full rounded-2xl text-body bg-secondary space-y-2">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button onClick={handleDisclosureButtonClick} className="disclosure-button flex w-full justify-between items-center rounded-lg bg-secondary px-4 py-2 text-left text-sm font-medium  hover:font-bold focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                <span className='flex items-center gap-4'>
                  {props.svg && <span dangerouslySetInnerHTML={{ __html: props.svg }}></span>}
                  {props.title ?? props.name}
                </span>
                <FaChevronDown
                  className={`${open ? "rotate-180 transform" : ""
                  } h-5 w-5 transition-all duration-300`}
                />
              </Disclosure.Button>

              <Disclosure.Panel static className={`px-4 pb-2 text-sm space-y-2 ${props.level > 0 ? "!pr-0" : ""} ${open ? "block" : "hidden"}`}>
                {
                  (props.content)?.map((item, index) => {
                    return <div key={index.toString()} >
                      {
                        (item.url && !item.content) && <HamburgerFixed level={props.level + 1} sectionId={`${props.sectionId}-${index}`} name={item.name} url={item.url} next={item.next} xtraProps={item.xtraProps} />
                      }
                      {
                        item.content && <AccordionItem parentClassName={`${props.parentSectionId}`} level={props.level + 1} sectionId={`${props.sectionId}`} parentSectionId={`${props.parentSectionId}-${props.sectionId}`} name={item.name} content={item.content}/>
                      }
                    </div>
                  })
                }
                {
                  (!props.content) && <AccordionContent data={props} />
                }
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
})
// build issue - Component definition is missing display name error
AccordionItem.displayName = "AccordionItem";
export default AccordionItem;